export type CountryInfo = {
  flag: string;
  country: string;
  contestant: string;
  song: string;
};

export const allCountries: Record<string, CountryInfo> = {
  austria: {
    flag: '🇦🇹',
    country: 'Austria',
    contestant: 'Kaleen',
    song: 'We Will Rave',
  },
  azerbaijan: {
    flag: '🇦🇿',
    country: 'Azerbaijan',
    contestant: 'Fahree feat. Ilkin Dovlatov',
    song: 'Özünlə Apar',
  },
  denmark: {
    flag: '🇩🇰',
    country: 'Denmark',
    contestant: 'Saba',
    song: 'Sand',
  },
  georgia: {
    flag: '🇬🇪',
    country: 'Georgia',
    contestant: 'Nutsa Buzaladze',
    song: 'Firefighter',
  },
  greece: {
    flag: '🇬🇷',
    country: 'Greece',
    contestant: 'Marina Satti',
    song: 'Zari',
  },
  iceland: {
    flag: '🇮🇸',
    country: 'Iceland',
    contestant: 'Hera Björk',
    song: 'Scared of Heights',
  },
  ireland: {
    flag: '🇮🇪',
    country: 'Ireland',
    contestant: 'Bambie Thug',
    song: 'Doomsday Blue',
  },
  latvia: {
    flag: '🇱🇻',
    country: 'Latvia',
    contestant: 'Dons',
    song: 'Hollow',
  },
  lithuania: {
    flag: '🇱🇹',
    country: 'Lithuania',
    contestant: 'Silvester Belt',
    song: 'Luktelk',
  },
  luxembourg: {
    flag: '🇱🇺',
    country: 'Luxembourg',
    contestant: 'Tali',
    song: 'Fighter',
  },
  malta: {
    flag: '🇲🇹',
    country: 'Malta',
    contestant: 'Sarah Bonnici',
    song: 'Loop',
  },
  netherlands: {
    flag: '🇳🇱',
    country: 'The Netherlands',
    contestant: 'Joost Klein',
    song: 'Europapa',
  },
  portugal: {
    flag: '🇵🇹',
    country: 'Portugal',
    contestant: 'Iolanda',
    song: 'Grito',
  },
  sanmarino: {
    flag: '🇸🇲',
    country: 'San Marino',
    contestant: 'Megara',
    song: '11:11',
  },
  switzerland: {
    flag: '🇨🇭',
    country: 'Switzerland',
    contestant: 'Nemo',
    song: 'The Code',
  },
  poland: {
    flag: '🇵🇱',
    country: 'Poland',
    contestant: 'Luna',
    song: 'The Tower',
  },
  serbia: {
    flag: '🇷🇸',
    country: 'Serbia',
    contestant: 'Teya Dora',
    song: 'Ramonda',
  },
  france: {
    flag: '🇫🇷',
    country: 'France',
    contestant: 'Slimane',
    song: 'Mon amour',
  },
  cyprus: {
    flag: '🇨🇾',
    country: 'Cyprus',
    contestant: 'Silia Kapsis',
    song: 'Liar',
  },
  spain: {
    flag: '🇪🇸',
    country: 'Spain',
    contestant: 'Nebulossa',
    song: 'Zorra',
  },
  sweden: {
    flag: '🇸🇪',
    country: 'Sweden',
    contestant: 'Marcus & Martinus',
    song: 'Unforgettable',
  },
  albania: {
    flag: '🇦🇱',
    country: 'Albania',
    contestant: 'Besa',
    song: 'Titan',
  },
  italy: { flag: '🇮🇹', country: 'Italy', contestant: 'Angelina Mango', song: 'La noia' },
  estonia: {
    flag: '🇪🇪',
    country: 'Estonia',
    contestant: '5MIINUST x Puuluup',
    song: '(nendest) narkootikumidest ei tea me (küll) midagi',
  },
  finland: { flag: '🇫🇮', country: 'Finland', contestant: 'Windows95man', song: 'No Rules!' },
  czechia: { flag: '🇨🇿', country: 'Czechia', contestant: 'Aiko', song: 'Pedestal' },
  australia: {
    flag: '🇦🇺',
    country: 'Australia',
    contestant: 'Electric Fields',
    song: 'One Milkali',
  },
  belgium: {
    flag: '🇧🇪',
    country: 'Belgium',
    contestant: 'Mustii',
    song: "Before The Party's Over",
  },
  armenia: { flag: '🇦🇲', country: 'Armenia', contestant: 'Ladaniva', song: 'Jako' },
  moldova: { flag: '🇲🇩', country: 'Moldova', contestant: 'Natalia Barbu', song: 'In The Middle' },
  ukraine: {
    flag: '🇺🇦',
    country: 'Ukraine',
    contestant: 'alyona alyona & Jerry Heil',
    song: 'Teresa & Maria',
  },
  norway: { flag: '🇳🇴', country: 'Norway', contestant: 'Gåte', song: 'Ulveham' },
  germany: {
    flag: '🇩🇪',
    country: 'Germany',
    contestant: 'Isaak',
    song: 'Always On The Run',
  },
  israel: { flag: '🇮🇱', country: 'Israel', contestant: 'Eden Golan', song: 'Hurricane' },
  slovenia: { flag: '🇸🇮', country: 'Slovenia', contestant: 'Raiven', song: 'Veronika' },
  croatia: { flag: '🇭🇷', country: 'Croatia', contestant: 'Baby Lasagna', song: 'Rim Tim Tagi Dim' },
  unitedKingdom: {
    flag: '🇬🇧',
    country: 'United Kingdom',
    contestant: 'Olly Alexander',
    song: 'Dizzy',
  },
};

export const runningOrder: (keyof typeof allCountries)[] = [
  'sweden',
  'ukraine',
  'germany',
  'luxembourg',
  // 'netherlands',
  'israel',
  'lithuania',
  'spain',
  'estonia',
  'ireland',
  'latvia',
    'greece',
  'unitedKingdom',
  'norway',
  'italy',
  'serbia',
  'finland',
  'portugal',
  'armenia',
  'cyprus',
  'switzerland',
  'slovenia',
  'croatia',
  'georgia',
  'france',
  'austria',
];

export const countries: typeof allCountries = runningOrder.reduce((agg, country) => {
  return {
    ...agg,
    [country]: allCountries[country]
  }
}, {})