import { FC, useState } from 'react';
import { Box, TextField, Button, Paper } from '@material-ui/core';
// @ts-ignore
import startCase from 'lodash/startCase';
import { useUser } from './useUser';

export const AuthProvider: FC = ({ children }) => {
  const { name, login } = useUser();
  const [localName, setLocalName] = useState('');

  if (!name) {
    return (
      // @ts-ignore
      <Box style={{ height: '90vh' }} display="flex" justifyContent="center" alignItems="center">
        <Paper>
          {/* @ts-ignore */}
          <Box width={200} p={2}>
            <TextField
              label="Jouw naam"
              variant="outlined"
              fullWidth
              value={localName}
              onChange={(e) => setLocalName(e.target.value)}
              autoComplete="off"
            />
          {/* @ts-ignore */}
            <Box mt={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                onClick={() => login(startCase(localName))}
                disabled={!localName.length}
              >
                Doorgaan
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  }

  return <div>{children}</div>;
};
