import { useLocalStorage } from '../shared/hooks/useLocalstorage';

export const useUser = () => {
  const [name, setName] = useLocalStorage('name', null);

  return {
    name: name as (string | null) || null,
    login: setName,
    logout: () => setName(''),
  };
};
