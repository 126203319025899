import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  styled,
  Box,
  FormControl,
  InputLabel,
  Select,
  Button,
  AppBar,
  Tabs,
  Tab,
  Chip,
  Grid,
  Divider,
  Card,
  CardHeader,
  Avatar,
  CardMedia,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { dbScore } from '../config/firebase';
import { runningOrder, countries, CountryInfo } from '../config/countries';
import { useUser } from '../auth/useUser';
// @ts-ignore
import cloneDeep from 'lodash/cloneDeep';
// @ts-ignore
import set from 'lodash/set';
// @ts-ignore
import get from 'lodash/get';
// @ts-ignore
import sortBy from 'lodash/sortBy';

const year = 2024;

const Contestant = styled(Typography)({
  fontSize: '0.6rem',
  marginLeft: '1rem',
});
const Country = styled(Typography)({
  fontSize: '0.8rem',
  lineHeight: 1.8,
  width: 120,
  // marginLeft: '1rem'
});
const TotalPoints = styled(Typography)({
  flex: 1,
  textAlign: 'right',
});

const MAX_SCORE = 12;
const SCORE_LIST = [12, 10, 8, 7, 6, 5, 4, 3, 2, 1];
type PersonName = string;
type CountryKey = string;
type Scores = Record<
  PersonName,
  Record<CountryKey, { act?: number; outfit?: number; song?: number }>
>;

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        //   @ts-ignore
        <Box pt={6}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const App = () => {
  const { name, logout } = useUser();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [scores, setScores] = useState<Scores>();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    dbScore.on('value', (snapshot) => {
      const value = snapshot.val();
      if (value) {
        setScores(value);
      } else {
      }
    });
  }, []);

  const onChange = (countryKey: string, score: 'song' | 'act' | 'outfit') => (e: any) => {
    const updatedScores = cloneDeep(scores || {});
    const value = parseInt(e.target.value);
    set(updatedScores, [name, countryKey, score], value ? Math.min(value, MAX_SCORE) : '');

    dbScore
      .set(updatedScores)
      .then(() => console.log('OK'))
      .catch((e) => console.error(e));
  };

  const calculateTotal = (name: string | null = '', countryKey: string) => {
    const songTotal = get(scores, [name, countryKey, 'song']) || 0;
    const actTotal = get(scores, [name, countryKey, 'act']) || 0;
    const outfitTotal = get(scores, [name, countryKey, 'outfit']) || 0;

    return Math.round((songTotal + actTotal + outfitTotal) / 3);
  };
  const calculateCountryTotal = (countryKey: string) => {
    const totals = Object.keys(scores || {})
      .map((personName) => calculateTotal(personName, countryKey))
      .filter((total) => total > 0);

    const endTotal = totals.reduce((total, personTotal) => total + personTotal, 0);

    return Math.round(endTotal / totals.length) || 0;
  };

  const sortedByTotalScore: [CountryInfo & { totalScore: number; key: CountryKey }] = sortBy(
    runningOrder.map((countryKey) => ({
      ...countries[countryKey],
      key: countryKey,
      totalScore: calculateCountryTotal(countryKey),
    })),
    'totalScore'
  ).reverse();

  const sortedByMyScore: [CountryInfo & { totalScore: number; key: CountryKey }] = sortBy(
    runningOrder.map((countryKey) => ({
      ...countries[countryKey],
      key: countryKey,
      totalScore: calculateTotal(name, countryKey),
    })),
    'totalScore'
  ).reverse();

  return (
    <div>
      <AppBar position="fixed">
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Countries" />
          <Tab label="Voters" />
          <Tab label="Total" />
          <Tab label="My" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        {runningOrder.map((countryKey) => {
          const country = countries[countryKey];
          return (
            <Accordion
              expanded={expanded === countryKey}
              onChange={handleChange(countryKey)}
              key={countryKey}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ marginRight: 8 }}>{country.flag}</Typography>
                {/* @ts-ignore */}
                <Country>{country.country}</Country>
                {/* @ts-ignore */}
                <Contestant>
                  {country.contestant}
                  <br />
                  {country.song}
                </Contestant>
                {/* @ts-ignore */}
                <TotalPoints>
                  {calculateCountryTotal(countryKey)}/{MAX_SCORE}
                </TotalPoints>
                {/*<Typography>I am an accordion</Typography>*/}
              </AccordionSummary>
              <AccordionDetails>
                {/* @ts-ignore */}
                <Box display="flex" flexDirection="column" width="100%">
                  <div
                    style={{
                      background: '50% no-repeat',
                      backgroundImage: `url("/${year}/${countryKey}.jpg")`,
                      backgroundSize: 'cover',
                      width: '100%',
                      maxWidth: 600,
                      height: 120,
                      marginBottom: '1rem',
                      borderRadius: 8,
                    }}
                  />

                  <Typography variant="body2">Mijn score</Typography>
                  <Divider style={{ marginBottom: 16, marginTop: 8 }} />
                  <Grid
                    container
                    alignItems="center"
                    style={{ marginBottom: 4, width: '100%' }}
                    spacing={2}
                  >
                    <Grid item style={{ flex: 1 }}>
                      <FormControl fullWidth>
                        <InputLabel>🎵 Song</InputLabel>
                        <Select
                          native
                          value={get(scores, [name, countryKey, 'song'])}
                          onChange={onChange(countryKey, 'song')}
                          inputProps={{
                            name: 'song',
                          }}
                        >
                          <option aria-label="None" value="" />
                          {Array(8)
                            .fill(null)
                            .map((_, index) => (
                              <option value={index + 1} key={index}>
                                {index + 1}
                              </option>
                            ))}
                          <option value={10}>{10}</option>
                          <option value={12}>{12}</option>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item style={{ flex: 1 }}>
                      <FormControl fullWidth>
                        <InputLabel>🕺 Act</InputLabel>
                        <Select
                          native
                          value={get(scores, [name, countryKey, 'act'])}
                          onChange={onChange(countryKey, 'act')}
                          inputProps={{
                            name: 'act',
                          }}
                        >
                          <option aria-label="None" value="" />
                          {Array(8)
                            .fill(null)
                            .map((_, index) => (
                              <option value={index + 1} key={index}>
                                {index + 1}
                              </option>
                            ))}
                          <option value={10}>{10}</option>
                          <option value={12}>{12}</option>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item style={{ flex: 1 }}>
                      <FormControl fullWidth>
                        <InputLabel>👗 Outfit</InputLabel>
                        <Select
                          native
                          value={get(scores, [name, countryKey, 'outfit'])}
                          onChange={onChange(countryKey, 'outfit')}
                          inputProps={{
                            name: 'outfit',
                          }}
                        >
                          <option aria-label="None" value="" />
                          {Array(8)
                            .fill(null)
                            .map((_, index) => (
                              <option value={index + 1} key={index}>
                                {index + 1}
                              </option>
                            ))}
                          <option value={10}>{10}</option>
                          <option value={12}>{12}</option>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item style={{ flex: 1, textAlign: 'right' }}>
                      <Typography variant="body2">
                        {calculateTotal(name, countryKey)} / {MAX_SCORE}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography variant="body2" style={{ marginTop: 8 }}>
                    Alle scores
                  </Typography>
                  <Divider style={{ marginBottom: 16, marginTop: 8 }} />

                  {Object.keys(scores || {}).map((personName) => {
                    const person = (scores || {})[personName];

                    return (
                      <Grid
                        // display="flex"
                        // justifyContent="space-between"
                        style={{ width: '100%' }}
                        // alignItems="center"
                        key={personName}
                        container
                        spacing={1}
                      >
                        <Grid item style={{ flex: 1 }}>
                          <Typography variant="body2" style={{ fontSize: '0.7rem' }}>
                            {personName}
                          </Typography>
                        </Grid>
                        <Grid item style={{ flex: 1 }}>
                          <Typography variant="body2" style={{ fontSize: '0.7rem' }}>
                            🎵 {get(person, [countryKey, 'song']) || 'n.b.'}
                          </Typography>
                        </Grid>
                        <Grid item style={{ flex: 1 }}>
                          <Typography variant="body2" style={{ fontSize: '0.7rem' }}>
                            🕺 {get(person, [countryKey, 'act']) || 'n.b.'}
                          </Typography>
                        </Grid>
                        <Grid item style={{ flex: 1 }}>
                          <Typography variant="body2" style={{ fontSize: '0.7rem' }}>
                            👗 {get(person, [countryKey, 'outfit']) || 'n.b.'}
                          </Typography>
                        </Grid>
                        <Grid item style={{ flex: 1, textAlign: 'right' }}>
                          <Typography variant="body2" style={{ fontSize: '0.7rem' }}>
                            {calculateTotal(personName, countryKey)} / {MAX_SCORE}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {scores &&
          Object.keys(scores).map((personName) => {
            const personScores = scores[personName];

            const totals: { country: string; score: number }[] = sortBy(
              Object.keys(personScores).map((countryKey) => {
                return {
                  country: countryKey,
                  score: calculateTotal(personName, countryKey),
                };
              }),
              'score'
            ).reverse();

            return (
              <Accordion
                expanded={expanded === personName}
                onChange={handleChange(personName)}
                key={personName}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    {personName} (
                    {totals
                      .filter((_, i) => i < 3)
                      .map((data) => data.country)
                      .join(', ')}
                    )
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* @ts-ignore */}
                  <Box display="flex" flexDirection="column" width="100%">
                    {totals.map(({ country, score }, index) => {
                      return (
                        //   @ts-ignore
                        <Box
                          key={country}
                          display="flex"
                          justifyContent="space-between"
                          width="100%"
                          mb={2}
                        >
                          {/* @ts-ignore */}
                          <Box display="flex">
                            {/* @ts-ignore */}
                            <Country style={{ width: 140 }}>
                              {index + 1}. {countries[country].flag} {countries[country].country}{' '}
                              {SCORE_LIST.length > index ? (
                                <Chip size="small" label={SCORE_LIST[index]} />
                              ) : (
                                ''
                              )}
                            </Country>
                            {/* @ts-ignore */}
                            <Contestant>
                              {countries[country].contestant}
                              <br />
                              {countries[country].song}
                            </Contestant>
                          </Box>
                          <Typography>
                            {Math.round(score)} / {MAX_SCORE}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        {sortedByTotalScore.map((country, index) => (
          <Card key={country.country} style={{ marginBottom: 16 }}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  {SCORE_LIST.length > index ? SCORE_LIST[index] : ''}
                </Avatar>
              }
              title={`${country.flag}  ${country.country}`}
              subheader={`${country.contestant} - ${country.song}`}
            />
            <CardMedia image={`/${year}/${country.key}.jpg`} style={{ height: 120 }} />
          </Card>
        ))}
      </TabPanel>

      <TabPanel value={tabValue} index={3}>
        {sortedByMyScore.map((country, index) => (
          <Card key={country.country} style={{ marginBottom: 16 }}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  {SCORE_LIST.length > index ? SCORE_LIST[index] : ''}
                </Avatar>
              }
              title={`${country.flag}  ${country.country}`}
              subheader={`${country.contestant} - ${country.song}`}
            />
            <CardMedia image={`/${year}/${country.key}.jpg`} style={{ height: 120 }} />
          </Card>
        ))}
      </TabPanel>

      {/* @ts-ignore */}
      <Box mt={8} mb={8} display="flex" justifyContent="center">
        <Button onClick={logout} color="secondary" variant="outlined">
          Afmelden
        </Button>
      </Box>
    </div>
  );
};
