import {createMuiTheme, CssBaseline, ThemeProvider} from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { App } from './components/App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './auth/AuthProvider';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={createMuiTheme({
      palette: {
        primary: {
          main: '#040241'
        },
        secondary: {
          main: '#ff0087'
        }
      }
    })}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <Switch>
            <Route path="/">
              <App />
            </Route>
          </Switch>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
