import firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyAwFWO7GHLFkJDVWchcj1MpDuKoLWuO9qo',
  authDomain: 'eurovision-1513d.firebaseapp.com',
  projectId: 'eurovision-1513d',
  storageBucket: 'eurovision-1513d.appspot.com',
  messagingSenderId: '467041015757',
  appId: '1:467041015757:web:9fcb2df12297b5190f5684',
  databaseURL: 'https://eurovision-1513d-default-rtdb.europe-west1.firebasedatabase.app',
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.database().ref();

export const dbScore = db.child('score2024-2');
